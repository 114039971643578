import moment from "moment";

export const validDate = (date: string) => {
    if (moment(date).isValid()) return date;
    return null;
}

type Formats = 'br' | 'brH' | 'usd' | 'usdH'

export const formatDate = (date: any, format?: Formats) => {
    let dtFormat = "DD/MM/YYYY";
    if (format) {
        switch (format) {
            case 'brH':
                dtFormat = "DD/MM/YYYY HH:mm";
                break;
            case 'usd':
                dtFormat = "YYYY-MM-DD";
                break;
            case 'usdH':
                dtFormat = "YYYY-MM-DD HH:mm";
                break;
            default:
                dtFormat = "DD/MM/YYYY";
                break;
        }
    }
    if (validDate(date)) return moment(date).format(dtFormat);
    return "";
}

export const startMes = (parseData: Date | string = new Date()) => moment(parseData).startOf('month');
export const endMes = (parseData: Date | string = new Date()) => moment(parseData).endOf('month');