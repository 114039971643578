import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Templete from "../../template/Template";
import { apiUrl } from "../../services/api";

export default function Home() {
    return (
        <Templete menuActive="home">
            <Grid container spacing={1}>
                <Grid item xs={12} sm={9} container alignItems="center" gap={1}>
                    <img src={`${apiUrl}/img/logo-sysprojud`} height={60} alt="Logo SysProJud"></img>
                    <h2 style={{ textAlign: 'center' }} >Bem vindo ao SysproJud</h2>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Paper elevation={4}>
                        <Typography variant="h6" component="div" align="center">
                            Dias Restantes
                        </Typography>
                        <Typography variant="h4" component="div" align="center">
                            15
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
        </Templete>
    );
}