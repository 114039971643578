import { Select, SelectChangeEvent, SelectProps } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

type SelectSimpleProps = SelectProps & {
    setState: Dispatch<SetStateAction<string | number | unknown>>
    next?: string,
    isNone?: boolean,
    isNoneNum?: boolean,
    options?: { value: string | number, label?: string, index?: string }[]
}

export default function SelectSimple({ label, value, next, setState, isNone, isNoneNum, options, ...rest }: SelectSimpleProps) {
    const handleChange = (event: SelectChangeEvent<typeof value>) => {
        setState(event.target.value);
        if(next){
            let nextFocus = document.getElementById(next);
            if(nextFocus) nextFocus?.focus();
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 5, width: "100%" }}>
            <span style={{ fontSize: 15 }}>{label}</span>
            <Select
                native
                size='small'
                {...rest}
                onChange={handleChange}
                fullWidth
            >
                {isNone && (
                    <option aria-label="None" value="">---</option>
                )}
                {isNoneNum && (
                    <option aria-label="None" value="0">---</option>
                )}

                {options?.map(opt => (
                    <option key={opt.value} value={opt.value} selected={opt.value === value}>
                        {opt.label ? opt.label : opt.value}
                    </option>
                ))}
            </Select>
        </div >
    );
}