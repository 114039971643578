import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import md5 from "md5";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import InputLabel from '../../components/Inputs/InputLabel';
import { api, apiUrl } from '../../services/api';
import { AlertInfo } from '../../components/Alerts/Alert';
import SimpleBackdrop from '../../components/Loading/SimpleBackdrop';

export default function RedefinirSenha() {
    const navigate = useNavigate();
    const [searchParms] = useSearchParams();
    const [openLoad, setOpenLoad] = useState(false);
    const [auth, setAuth] = useState("");
    const [senha_usuario, setSenhaUsuario] = useState("");
    const [senha, setSenha] = useState("");
    const [id_usuario, setIdUsuario] = useState(null);
    const [id_escritorio_usuario, setIdEscritorioUsuario] = useState<number | string | null>(null);

    async function handleSenha() {
        if(senha === senha_usuario){
            setOpenLoad(true);
            try {
                const where = id_escritorio_usuario ? { id_escritorio_usuario } : { id_usuario };
                const data = { senha_usuario: md5(senha_usuario) };
                const response = await api.post(`/resetsenha`, { where, data, auth });
                console.log(response);
                if(response && response.status && response.data) {
                    AlertInfo({icon: 'success', title: 'DADOS GRAVADOS'}).then(() => {
                        navigate('/login');
                    });
                }
            } catch (error) {
                console.log(error);
                AlertInfo({icon: 'error', title: 'Falha na conexão'});
            }
            setOpenLoad(false);
        }else{
            AlertInfo({icon: 'info', title: 'Senhas diferentes'});
        }
    }

    function loadData() {
        const id = searchParms.get('id');
        const token = searchParms.get('auth');
        if(id) setIdEscritorioUsuario(id);
        if(token) setAuth(token);
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div style={{ backgroundColor: '#F1F1F1', width: '100%', height: '100vh', padding: '8px 152px' }}>
            <Paper elevation={4} sx={{ padding: 4 }}>
                <SimpleBackdrop open={openLoad} />
                <Grid container spacing={1}>
                    <Grid item xs={12} container justifyContent="center">
                        <img src={`${apiUrl}/img/logo-sysprojud`} height={60} alt="Logo SysProJud"></img>
                    </Grid>
                    <Grid item xs={12}>
                        <h2 style={{ textAlign: 'center' }} >Redefinir Senha SysproJud</h2>
                    </Grid>
                    <Grid item xs={false} sm={4}></Grid>
                    <Grid item xs={12} sm={4}>
                        <InputLabel
                            label="Digite a nova senha"
                            type='password'
                            next='confirme'
                            autoFocus
                            value={senha_usuario}
                            setState={setSenhaUsuario}
                        />
                    </Grid>
                    <Grid item xs={false} sm={4}></Grid>
                    <Grid item xs={false} sm={4}></Grid>
                    <Grid item xs={12} sm={4}>
                        <InputLabel
                            id="confirme"
                            label="Confirme a nova senha"
                            type='password'
                            value={senha}
                            setState={setSenha}
                        />
                    </Grid>
                    <Grid item xs={false} sm={4}></Grid>
                    <Grid item xs={false} sm={4}></Grid>
                    <Grid item xs={12} sm={4}>
                        <Button
                            variant="contained"
                            fullWidth
                            color="success"
                            onClick={handleSenha}
                        >
                            REDEFINIR
                        </Button>
                    </Grid>
                    <Grid item xs={false} sm={4}></Grid>
                </Grid>
            </Paper>
        </div>
    );
}