import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { TextField } from "@mui/material";
import { Autocomplete } from '@mui/material';
import { api } from '../../services/api';

type InputCityProps = {
    uf: string | unknown
    id?: string
    value?: string
    setValue?: Dispatch<SetStateAction<string>>
    next?: string
    label?: string
}

export default function InputCity({ uf, value, setValue, next, label = 'Cidade', ...rest }: InputCityProps) {
    const [cidades, setCidades] = useState<string[]>([]);

    async function loadCitys() {
        if (uf) {
            setCidades([]);
            try {
                const response = await api.get(`/cidades/${uf}`);
                if (response && response.status === 200 && response.data) {
                    if (!response.data.error) setCidades(response.data);
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    const handleComplete = (event: any, newValue: string | null) => {
        if(setValue) setValue(newValue ? newValue : "");
        if(next){
            let nextInput = document.getElementById(next);
            if(nextInput) nextInput.focus();
        }
    }

    useEffect(() => {
        loadCitys();
    }, [uf]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
            <span style={{ fontSize: 15 }}>{ label }</span>
            <Autocomplete
                {...rest}
                value={value}
                onChange={handleComplete}
                disablePortal
                options={cidades}
                renderInput={(params) => <TextField {...params} size='small' />}
            />
        </div>
    );
}