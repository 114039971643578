import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { useTheme } from "@mui/material/styles";

type ButonCardProps = {
    icon: JSX.Element,
    title: string,
    handle?: () => void
};

export default function ButtonCard({ icon, title, handle } : ButonCardProps) {
    const theme = useTheme();
    return (
        <Card color="primary">
            <CardActionArea 
                onClick={handle}
                style={{
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    display: 'flex', 
                    flexDirection: 'column', 
                    backgroundColor: theme.palette.primary.main, 
                    color: theme.palette.primary.contrastText,
                    paddingTop: 5
                }}
            >
                <CardContent style={{padding: 0}} >
                    {icon}
                </CardContent>
                <CardContent style={{padding: 0}}>
                    <Typography gutterBottom variant="h5" component="div">
                        {title}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
