import { Grid } from "@mui/material";
import Modal from "../../components/Modal/Modal";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { formatDate } from "../../utilities/date";
import TableDataSimple from "../../components/Tables/TableDataSimple";
import { AlertInfo } from "../../components/Alerts/Alert";
import { api } from "../../services/api";
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import { ClienteType } from "../../types/ClienteType";

type ListaClientesProps = {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    setInfoCliente?: Dispatch<SetStateAction<ClienteType | null>>
}

export default function ListaClientes({ open, setOpen, setInfoCliente }: ListaClientesProps) {
    const [openLoad, setOpenLoad] = useState(false);
    const [rows, setRows] = useState([]);
    const columns = [
        {
            id: 'id_cliente',
            numeric: false,
            label: 'COD.',
        },
        {
            id: 'nome_cliente',
            numeric: false,
            label: 'NOME',
        },
        {
            id: 'data_nascimento_cliente',
            numeric: false,
            label: 'NASCIMENTO',
            format: (dt: any) => formatDate(dt),
        },
        {
            id: 'cpf_cliente',
            numeric: false,
            label: 'CPF',
        },
        {
            id: 'rg_cliente',
            numeric: false,
            label: 'RG',
        },
        {
            id: 'telefone_cliente',
            numeric: false,
            label: 'TELEFONE',
        },
        {
            id: 'cidade_cliente',
            numeric: false,
            label: 'CIDADE',
        },
    ];

    async function loadClientes() {
        setOpenLoad(true);
        try {
            const response = await api.get(`/clientes`);
            if (response && response.status === 200 && response.data) {
                if (response.data.error) {
                    AlertInfo({ icon: 'error', title: response.data.message })
                } else {
                    if (response.data.length > 0) setRows(response.data);
                    else AlertInfo({ icon: 'info', title: "Nenhum cliente localizado" });
                }
            }
        } catch (error) {
            console.log(error);
            AlertInfo({ icon: 'error', title: "Falha na conexão" });
        }
        setOpenLoad(false);
    }

    function handleClick(cli: ClienteType) {
        if(setInfoCliente){
            setInfoCliente(cli);
            setOpen(false);
        }
    }

    useEffect(() => {
        loadClientes();
    }, []);

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title="Buscar Cliente"
            maxWidth="md"
            zIndex={2222}
        // actionClose={resetStates}
        >
            <Grid container spacing={1}>
                <SimpleBackdrop open={openLoad} />
                {rows.length > 0 && (
                    <Grid item xs={12}>
                        <TableDataSimple
                            rows={rows}
                            columns={columns}
                            handleClick={handleClick}
                        />
                    </Grid>
                )}
            </Grid>
        </Modal>
    );
}