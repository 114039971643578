import { Paper } from "@mui/material";
import "./card.css";

type CardSimpleProps = {
    title: string
    txt: string | number
    color?: string
    handle?: () => void
}

export default function CardSimple({ title, txt, color, handle } : CardSimpleProps) {
    return (
        <Paper elevation={3} onClick={handle} sx={{cursor: 'pointer'}}>
            <div className={`titleCard ${color}`}>
                {title}
            </div>
            <div className="txtCard">
                {txt}
            </div>
        </Paper>
    );
}