import MyRoutes from './routes';
import MaterialTheme from './styles/MaterialTheme';
import './styles/globalStyle.css';

function App() {
    return (
        <MaterialTheme>
            <MyRoutes />
        </MaterialTheme>
    );
}

export default App;