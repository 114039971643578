import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import Templete from "../../template/Template";
import TableDataSimple from "../../components/Tables/TableDataSimple";
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import { AlertInfo } from "../../components/Alerts/Alert";
import { UsuarioType } from "../../types/UsuarioType";
import { api } from "../../services/api";
import { isPermite } from "../../utilities/isPermite";

export default function Usuarios() {
    const navigate = useNavigate();
    const [openLoad, setOpenLoad] = useState(false);
    const [infoLogin, setInfoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin') || ''));
    const [rows, setRows] = useState([]);
    const columns = [
        {
            id: 'nome_usuario',
            numeric: false,
            label: 'USUARIO',
            minWidth: 300
        },
        {
            id: 'email_usuario',
            numeric: false,
            disablePadding: false,
            label: 'EMAIL',
        },
        {
            id: 'telefone_usuario',
            numeric: false,
            disablePadding: false,
            label: 'TELEFONE',
        }
    ];

    async function loadData() {
        setOpenLoad(true);
        try {
            const response = await api.get(`/usuarios`);
            if (response && response.status === 200 && response.data) {
                if (response.data.error) {
                    AlertInfo({ icon: 'error', title: response.data.message })
                } else {
                    setRows(response.data);
                }
            }
        } catch (error) {
            console.log(error);
            AlertInfo({ icon: 'error', title: "FALHA NA CONEXÃO" });
        }
        setOpenLoad(false);
    }

    function handleNewUsuario() {
        if (infoLogin && infoLogin.plano_escritorio) {
            if (Number(infoLogin.plano_escritorio) === 1 && rows.length === 1) {
                AlertInfo({ icon: 'info', title: "ACESSO NEGADO", text: "Plano Basic permite apenas 1 usuario" });
            } else if (Number(infoLogin.plano_escritorio) === 2 && rows.length === 3) {
                AlertInfo({ icon: 'info', title: "ACESSO NEGADO", text: "Plano Pro permite apenas 3 usuarios" });
            } else {
                navigate(`/cadastrousuario`);
            }
        }
    }

    function handleClick(dataUsuario: UsuarioType) {
        if (isPermite("1")) {
            if (dataUsuario && dataUsuario.id_usuario) {
                navigate(`/cadastrousuario?id=${dataUsuario.id_usuario}`);
            }
        }
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <Templete menuActive="usuarios">
            <Grid container spacing={1}>
                <Grid item xs={12} sm={2}>
                    {isPermite("1") && (
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={handleNewUsuario}
                        >
                            NOVO USUARIO
                        </Button>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <TableDataSimple
                        rows={rows}
                        columns={columns}
                        handleClick={handleClick}
                    />
                </Grid>
                <SimpleBackdrop open={openLoad} />
            </Grid>
        </Templete>
    );
}