import { createTheme, ThemeProvider } from '@mui/material/styles';

export const colorTheme = createTheme({
    palette: {
        primary: {
            main: '#091114'
        },
        secondary: {
            main: '#FFCB23',
        },
        error: {
            main: '#96031A',
        },
        success: {
            main: '#00a65a'
        },
        info: {
            main: '#101F33'
        },
        warning: {
            main: '#F4F4F9'
        }
    },
});

type MaterialThemeProps = {
    children: JSX.Element
}

export default function MaterialTheme({ children }: MaterialThemeProps) {
    return (
        <ThemeProvider theme={colorTheme}>
            {children}
        </ThemeProvider>
    );
}