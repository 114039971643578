import { useNavigate } from "react-router-dom";
import { FaUsersViewfinder } from "react-icons/fa6";
import Grid from "@mui/material/Grid";
import Templete from "../../template/Template";
import ButtonCard from "../../components/ButtonCard/ButonCard";

export default function Cadastros() {
    const navigate = useNavigate();

    return(
        <Templete menuActive="cadastros">
            <Grid container spacing={1}>
                <Grid item xs={12} sm={3}>
                    <ButtonCard
                        icon={<FaUsersViewfinder size={55} />}
                        title="CORRETORES"
                        handle={() => navigate("/corretores")}
                    />
                </Grid>
            </Grid>
        </Templete>
    );
}