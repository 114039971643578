export const cpf = (value: string): string => {
	// Remove todos os caracteres não numéricos
	const onlyNumbers = value.replace(/\D/g, '');

	// Aplica a máscara do CPF conforme o número de dígitos
	let maskedValue = onlyNumbers;
	if (onlyNumbers.length > 3) maskedValue = onlyNumbers.slice(0, 3) + '.' + onlyNumbers.slice(3, 6);
	if (onlyNumbers.length > 6) maskedValue += '.' + onlyNumbers.slice(6, 9);
	if (onlyNumbers.length > 9) maskedValue += '-' + onlyNumbers.slice(9);

	// Limita o número de caracteres do input em 14 (tamanho de um CPF com máscara)
	return maskedValue.slice(0, 14);
};

export const cnpj = (value: string): string => {
	// Remove todos os caracteres não numéricos
	const onlyNumbers = value.replace(/\D/g, '');

	// Aplica a máscara do CPF conforme o número de dígitos
	let maskedValue = onlyNumbers;
	if (onlyNumbers.length > 2) maskedValue = onlyNumbers.slice(0, 2) + '.' + onlyNumbers.slice(2, 5);
	if (onlyNumbers.length > 5) maskedValue += '.' + onlyNumbers.slice(5, 8);
	if (onlyNumbers.length > 8) maskedValue += '/' + onlyNumbers.slice(8, 12);
	if (onlyNumbers.length > 12) maskedValue += '-' + onlyNumbers.slice(12);

	// Limita o número de caracteres do input em 14 (tamanho de um CPF com máscara)
	return maskedValue.slice(0, 18);
};

export const telefone = (value: string): string => {
	// Remove todos os caracteres não numéricos
	const onlyNumbers = value.replace(/\D/g, '');

	// Aplica a máscara do telefone conforme o número de dígitos
	let maskedValue = onlyNumbers;
	if (onlyNumbers.length > 2) {
		maskedValue = '(' + onlyNumbers.slice(0, 2) + ') ' + onlyNumbers.slice(2, 7);
	}
	if (onlyNumbers.length > 7) {
		maskedValue += '-' + onlyNumbers.slice(7, 11);
	}
	return maskedValue.slice(0, 15);
};

export const cep = (value: string): string => {
	// Remove todos os caracteres não numéricos
	const onlyNumbers = value.replace(/\D/g, '');

	// Aplica a máscara do telefone conforme o número de dígitos
	let maskedValue = onlyNumbers;
	if (onlyNumbers.length > 5) {
		maskedValue = onlyNumbers.slice(0, 5) + '-' + onlyNumbers.slice(5, 11);
	}
	return maskedValue.slice(0, 9);
};