import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FaTrash } from "react-icons/fa";
import { Button, Grid, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import Modal from "../../components/Modal/Modal";
import InputLabel from "../../components/Inputs/InputLabel";
import InputCity from "../../components/Inputs/InputCity";
import SelectSimple from "../../components/Selects/SelectSimple";
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import { AlertConfirm, AlertInfo } from "../../components/Alerts/Alert";
import { formatDate } from "../../utilities/date";
import { arrayUf } from "../../data/dataUf";
import { api } from "../../services/api";
import { CorretorType } from "../../types/Corretor";

type HistoricoProcessoProps = {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    id_corretor: number | null
    setIdCorretor: Dispatch<SetStateAction<number | null>>
}

export default function Corretor({ open, setOpen, id_corretor, setIdCorretor }: HistoricoProcessoProps) {
    const [openLoad, setOpenLoad] = useState(false);
    const [abreviacao_corretor, setAbreviacao] = useState("");
    const [nome_corretor, setNome] = useState("");
    const [endereco_corretor, setEndereco] = useState("");
    const [uf_corretor, setUf] = useState<string | number | unknown>("PA");
    const [cidade_corretor, setCidade] = useState("");
    const [telefone_corretor, setTelefone] = useState("");
    const [email_corretor, setEmail] = useState("");
    const [adicional_corretor, setAdicional] = useState("");

    function clearInputs() {
        setIdCorretor(null);
        setAbreviacao("");
        setNome("");
        setEndereco("");
        setUf("PA");
        setCidade("");
        setTelefone("");
        setEmail("");
        setAdicional("");
    }

    async function handleSave() {
        setOpenLoad(true);
        try {
            const data = {
                id_corretor, abreviacao_corretor, nome_corretor,
                endereco_corretor, uf_corretor, cidade_corretor,
                telefone_corretor, email_corretor, adicional_corretor,
                corretor_ativo: 1, update_corretor: formatDate(new Date(), 'usdH')
            };
            const channel = id_corretor && Number(id_corretor) > 0 ? `/updatecorretor` : `/createcorretor`;
            const response = await api.post(channel, data);
            if (response && response.status === 200 && response.data) {
                if (response.data.error) {
                    AlertInfo({ icon: 'error', title: response.data.message });
                } else {
                    AlertInfo({ icon: 'success', title: "DADOS GRAVADOS" });
                    setOpen(false);
                }
            }
        } catch (error) {
            console.log(error)
            AlertInfo({ icon: 'error', title: "Falha na Conexão" });
        }
        setOpenLoad(false);
    }

    function setDataCorretor(dataCorretor: CorretorType) {
        setAbreviacao(dataCorretor.abreviacao_corretor);
        setNome(dataCorretor.nome_corretor);
        setEndereco(dataCorretor.endereco_corretor);
        setUf(dataCorretor.uf_corretor);
        setCidade(dataCorretor.cidade_corretor);
        setTelefone(dataCorretor.telefone_corretor);
        setEmail(dataCorretor.email_corretor);
        setAdicional(dataCorretor.adicional_corretor);
    }

    async function loadCorretor() {
        setOpenLoad(true);
        try {
            const response = await api.post(`/corretor`, { id_corretor });
            if (response && response.status === 200 && response.data) {
                if (response.data.error) {
                    AlertInfo({ icon: 'error', title: response.data.message });
                } else {
                    setDataCorretor(response.data[0]);
                }
            }
        } catch (error) {
            console.log(error);
            AlertInfo({ icon: 'error', title: 'Falha na Conexão' });
        }
        setOpenLoad(false);
    }

    function handleDelete() {
        AlertConfirm({ title: "Deletar Corretor?" }).then(async res => {
            if (res.isConfirmed) {
                setOpenLoad(true);
                try {
                    const data = {
                        id_corretor, corretor_ativo: 0
                    };
                    const response = await api.post(`/updatecorretor`, data);
                    if(response && response.status === 200 && response.data){
                        if(response.data.success){
                            AlertInfo({ icon: 'success', title: 'Corretor Deletado' });
                            setOpen(false);
                        }
                    }
                } catch (error) {
                    console.log(error);
                    AlertInfo({ icon: 'error', title: 'Falha na Conexão' });
                }
                setOpenLoad(false);
            }
        });
    }

    useEffect(() => {
        if (id_corretor && Number(id_corretor) > 0) loadCorretor();
    }, [id_corretor]);

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title="Cadastro Corretor"
            maxWidth="md"
            zIndex={2222}
            actionClose={clearInputs}
        >
            <SimpleBackdrop open={openLoad} />
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4}>
                    <InputLabel
                        id="abreviacao_corretor"
                        label="ABREVIÇÃO"
                        value={abreviacao_corretor}
                        setState={setAbreviacao}
                        next="nome_corretor"
                    />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <InputLabel
                        id="nome_corretor"
                        label="NOME COMPLETO"
                        value={nome_corretor}
                        setState={setNome}
                        next="endereco_corretor"
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel
                        id="endereco_corretor"
                        label="ENDEREÇO COMPLETO"
                        value={endereco_corretor}
                        setState={setEndereco}
                        next="cidade_corretor"
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <SelectSimple
                        label="UF"
                        id="uf"
                        value={uf_corretor}
                        setState={setUf}
                        options={arrayUf}
                        next="cidade"
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <InputCity
                        id="cidade_corretor"
                        uf={uf_corretor}
                        value={cidade_corretor}
                        setValue={setCidade}
                        next="telefone_corretor"
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <InputLabel
                        id="telefone_corretor"
                        label="TELEFONE"
                        value={telefone_corretor}
                        setState={setTelefone}
                        next="email_corretor"
                        mask="telefone"
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <InputLabel
                        id="email_corretor"
                        label="EMAIL"
                        value={email_corretor}
                        setState={setEmail}
                        next="adicional_corretor"
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel
                        id="adicional_corretor"
                        label="ADICIONAL"
                        value={adicional_corretor}
                        setState={setAdicional}
                    />
                </Grid>

                <Grid item xs={false} sm={10}></Grid>
                <Grid item xs={12} sm={2} container justifyContent="flex-end">
                    {id_corretor && Number(id_corretor) > 0 && (
                        <Button
                            variant="contained"
                            color="error"
                            onClick={handleDelete}
                        >
                            <FaTrash /> &nbsp; DELETAR
                        </Button>
                    )}
                </Grid>

                <Grid item xs={false} sm={4}></Grid>
                <Grid item xs={12} sm={4}>
                    <Button
                        variant="contained"
                        fullWidth
                        color="success"
                        onClick={handleSave}
                    >
                        SALVAR
                    </Button>
                </Grid>
                <Grid item xs={false} sm={4}></Grid>
            </Grid>
        </Modal>
    );
}