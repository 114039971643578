import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
// import isPermission, { allPermissions } from './services/permission';

import Login from './pages/Login/Login';
import Home from './pages/Home/Home';
import Usuarios from './pages/Usuarios/Usuarios';
import CadastroUsuario from './pages/Usuarios/CadastroUsuario';
import Profile from './pages/Usuarios/Profile';
import Escritorio from './pages/Escritorio/Escritorio';
import Clientes from './pages/Clientes/Clientes';
import Processos from './pages/Processos/Processos';
import CadastroProcesso from './pages/Processos/CadastroProcesso';
import Cadastros from './pages/Cadastros/Cadastros';
import Corretores from './pages/Cadastros/Corretores';

import CadastroEscritorio from './pages/Escritorio/CadastroEscritorio';
import Congratulations from './pages/Escritorio/Congratulations';
import RedefinirSenha from './pages/Login/RedefinirSenha';

const isAuthentication = (path: any) => {
    const infoLogin = sessionStorage.getItem('infoLogin') || null;
    if(infoLogin){
        // if(path){
        //     const getPermission = allPermissions.filter(i => i.route === path);
        //     if(getPermission.length > 0){
        //         if(isPermission(getPermission[0].id)) return true;
        //     }
        //     return false;
        // }
        return true;
    }else{
        return false;
    }
};

type PrivateRouteProps = {
    component: () => JSX.Element,
    path?: any,
};

const PrivateRoute = (props: PrivateRouteProps) => {
    const { component: Component, path = null } = props;
    return isAuthentication(path) ? <Component/> : <Navigate replace to={"/login"} />
};

const LoginRoute = () => {
    return isAuthentication(null) ? <Navigate replace to={"/"} /> : <Login />
}

export default function MyRoutes() {
    return(
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<LoginRoute />} />
                <Route path="/cadastro" element={<CadastroEscritorio />} />
                <Route path="/congratulations" element={<Congratulations />} />
                <Route path="/redefinirsenha" element={<RedefinirSenha />} />
                
                <Route path="/" element={<PrivateRoute component={Home} />} />
                <Route path="/usuarios" element={<PrivateRoute component={Usuarios} />} />
                <Route path="/cadastrousuario" element={<PrivateRoute component={CadastroUsuario} />} />
                <Route path="/profile" element={<PrivateRoute component={Profile} />} />
                <Route path="/escritorio" element={<PrivateRoute component={Escritorio} />} />
                <Route path="/clientes" element={<PrivateRoute component={Clientes} />} />
                <Route path="/processos" element={<PrivateRoute component={Processos} />} />
                <Route path="/cadastroprocesso" element={<PrivateRoute component={CadastroProcesso} />} />
                <Route path="/cadastros" element={<PrivateRoute component={Cadastros} />} />
                <Route path="/corretores" element={<PrivateRoute component={Corretores} />} />
            </Routes>
        </BrowserRouter>
    );
}