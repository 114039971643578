import Swal from 'sweetalert2';
import { colorTheme } from '../../styles/MaterialTheme';
import "./alert.css";

type AlertInfoProps = {
    icon?: 'success' | 'error' | 'info'
    title: string
    text?: string
}

export const AlertInfo = ({icon, ...props} : AlertInfoProps) => {
    const confirmButtonColor = (icon && colorTheme.palette[icon]) ? colorTheme.palette[icon].main : colorTheme.palette.primary.main;
    return Swal.fire({
        icon,
        ...props,
        confirmButtonColor,
    })
}

export const AlertConfirm = ({...props} : AlertInfoProps) => {
    return Swal.fire({
        ...props,
        showCancelButton: true,
        confirmButtonColor: colorTheme.palette.success.main,
        cancelButtonColor: colorTheme.palette.primary.main,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
    })
}