export const permissions = [
    {
        id: 'usuario',
        label: 'Usuarios',
        options: [
            { value: 1, label: 'Criar/Editar Usuario', check: false },
            { value: 2, label: 'Deletar Usuario', check: false },
        ]
    },
    {
        id: 'clientes',
        label: 'Clientes',
        options: [
            { value: 10, label: 'Criar/Editar Cliente', check: false },
            { value: 11, label: 'Deletar Cliente', check: false },
        ]
    },
    {
        id: 'processos',
        label: 'Processos',
        options: [
            { value: 30, label: 'Visualizar Processos', check: false },
            { value: 31, label: 'Cadastrar Processos', check: false },
            { value: 32, label: 'Editar Processos', check: false },
        ]
    },
    {
        id: 'cadastros',
        label: 'Cadastros',
        options: [
            { value: 40, label: 'Cadastro/Editar Corretor', check: false },
            { value: 41, label: 'Deletar Corretor', check: false },
        ]
    },
    {
        id: 'escritorio',
        label: 'Escritorio',
        options: [
            { value: 20, label: 'Editar Dados Escritorio', check: false },
        ]
    },
];