import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import { LoginType } from '../types/LoginType';
import { apiUrl } from '../services/api';

interface HeaderProps {
    onDrawerToggle: () => void;
    infoLogin: LoginType
}

export default function Header(props: HeaderProps) {
    const { onDrawerToggle, infoLogin } = props;
    const firstName = String(infoLogin.nome_usuario).split(" ");
    const theme = useTheme();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const handleProfile = () => {
        navigate("/profile");
    }

    const logout = () => {
        sessionStorage.removeItem('infoLogin');
        navigate("/login");
    }

    return (
        <React.Fragment>
            <AppBar color="primary" position="sticky" elevation={0}>
                <Toolbar>
                    <Grid container spacing={1} alignItems="center" justifyContent="space-between">
                        <Grid sx={{ display: { sm: 'none', xs: 'block' } }} item>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={onDrawerToggle}
                                edge="start"
                            >
                                <MenuIcon />
                            </IconButton>
                        </Grid>
                        <Grid item sx={{ display: { sm: 'block', xs: 'none' } }}>
                            <Typography color="inherit" variant="h5" component="h2">
                                {infoLogin.nome_escritorio}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Tooltip title="Alerts • No alerts">
                                <IconButton color="inherit">
                                    <NotificationsIcon />
                                </IconButton>
                            </Tooltip>
                            <IconButton color="secondary" sx={{ p: 0.5, gap: 1 }} onClick={handleMenu}>
                                <Avatar 
                                    src={String(infoLogin.photo_usuario).length > 0 ? `${apiUrl}/userProfile/${infoLogin.photo_usuario}` : ""}
                                    alt={firstName[0]} 
                                    sx={{ bgcolor: theme.palette.secondary.main }}
                                />
                                <Typography color="inherit" variant="h6" component="h4" sx={{ display: { sm: 'block', xs: 'none' } }}>
                                    {firstName[0]}
                                </Typography>
                            </IconButton>

                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleCloseMenu}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={handleProfile}>
                                    <ListItemIcon>
                                        <AccountCircleIcon fontSize="small" color='primary' />
                                    </ListItemIcon>
                                    <ListItemText>Perfil</ListItemText>
                                </MenuItem>
                                <MenuItem onClick={logout}>
                                    <ListItemIcon>
                                        <LogoutIcon fontSize="small" color='primary' />
                                    </ListItemIcon>
                                    <ListItemText>Logout</ListItemText>
                                </MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
}