import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import LoginCard from './LoginCard';
import InfoCard from './InfoCard';
import './login.css';

export default function Login() {
    return (
        <div className="containerLogin">
            <Grid container spacing={1}>
                <Grid item xs={false} sm={2}></Grid>
                <Grid item xs={12} sm={8}>
                    <Paper elevation={5} className="paperContainer">
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={6}>
                                <LoginCard />
                            </Grid>
                            <Grid item xs={false} sm={6}>
                                <InfoCard />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={false} sm={8}></Grid>
            </Grid>
        </div>
    );
}