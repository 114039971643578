import { AppBar, Grid, IconButton, TextField, Toolbar, Tooltip, TextFieldProps } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

type InputSearchProps = TextFieldProps & {
    handleClick?: () => void
}

export default function InputSearch({handleClick, ...rest} : InputSearchProps) {
    return (
        <AppBar
            position="static"
            elevation={0}
            sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.12)', backgroundColor: "#fff" }}
        >
            <Toolbar>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs>
                        <TextField
                            {...rest}
                            fullWidth
                            variant="standard"
                            InputProps={{
                                disableUnderline: true,
                                sx: { fontSize: 'default' },
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Tooltip title="Buscar">
                            <IconButton onClick={handleClick}>
                                <SearchIcon color="primary" sx={{ display: 'block' }} />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
}