import React from "react";
import { TextField, TextFieldProps } from "@mui/material";
import { cep, cpf, cnpj, telefone } from "./masks";

type InputLabelProps = TextFieldProps & {
    next?: string
    setState?: React.Dispatch<React.SetStateAction<string>>
    mask?: 'cpf' | 'telefone' | 'cep' | 'cnpj'
}

export default function InputLabel({ label, next, setState, mask, ...rest }: InputLabelProps) {
    const allMasks = { cpf, cnpj, telefone, cep };

    function handleNext(event : any) {
        if(event.key === 'Enter'){
            if(next){
                let nextInput = document.getElementById(next);
                if(nextInput) nextInput?.focus();
            }
        }
    }

    function changeInput(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        if(setState){
            if(mask && allMasks[mask]){
                const addMask = allMasks[mask];
                setState(addMask(e.target.value));
            }else{
                setState(e.target.value);
            }
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 5, width: "100%" }}>
            <span style={{ fontSize: 15 }}>{label}</span>
            <TextField
                variant="outlined"
                size="small"
                fullWidth
                onChange={changeInput}
                onKeyDown={handleNext}
                {...rest}
            />
        </div>
    );
}