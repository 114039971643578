import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Templete from "../../template/Template";
import TableDataSimple from "../../components/Tables/TableDataSimple";
import { api, apiUrl } from "../../services/api";
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import Corretor from "../../modals/Cadastros/Corretor";
import { AlertInfo } from "../../components/Alerts/Alert";
import { CorretorType } from "../../types/Corretor";

export default function Corretores() {
    const [openLoad, setOpenLoad] = useState(false);
    const [openModalCadastro, setOpenModalCadastro] = useState(false);
    const [id_corretor, setIdCorretor] = useState<number | null>(null);
    const [rows, setRows] = useState([]);
    const columns = [
        {
            id: 'abreviacao_corretor',
            numeric: false,
            label: 'ABREVIAÇÃO',
            minWidth: 150
        },
        {
            id: 'email_corretor',
            numeric: false,
            disablePadding: false,
            label: 'EMAIL',
        },
        {
            id: 'telefone_corretor',
            numeric: false,
            disablePadding: false,
            label: 'TELEFONE',
        }
    ];

    function handleClick(corretor: CorretorType) {
        if(corretor && corretor.id_corretor){
            setIdCorretor(corretor.id_corretor);
            setOpenModalCadastro(true);
        }
    }

    async function loadData() {
        setOpenLoad(true);
        try {
            const response = await api.get(`/corretores`);
            if(response && response.status === 200 && response.data){
                if(response.data.error){
                    AlertInfo({icon: 'error', title: response.data.message});
                }else{
                    setRows(response.data);
                }
            }
        } catch (error) {
            console.log(error);
            AlertInfo({icon: 'error', title: 'Falha na Conexão'});
        }
        setOpenLoad(false);
    }

    useEffect(() => {
        if(!openModalCadastro) loadData();
    }, [openModalCadastro]);

    return (
        <Templete menuActive="cadastros">
            <SimpleBackdrop open={openLoad} />
            <Corretor
                open={openModalCadastro}
                setOpen={setOpenModalCadastro}
                id_corretor={id_corretor}
                setIdCorretor={setIdCorretor}
            />

            <Grid container spacing={1}>
                <Grid item xs={12} sm={2}>
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={() => setOpenModalCadastro(true)}
                    >
                        NOVO
                    </Button>
                </Grid>
                {rows.length > 0 && (
                    <>
                        <Grid item xs={12}>
                            <h2 style={{ textAlign: 'center' }}>CORRETORES</h2>
                        </Grid>
                        <Grid item xs={12}>
                            <TableDataSimple
                                rows={rows}
                                columns={columns}
                                handleClick={handleClick}
                            />
                        </Grid>
                    </>
                )}
                {rows.length === 0 && (
                    <Grid item xs={12} container spacing={1} justifyContent="center" direction="column" alignItems="center">
                        <br />
                        <img src={`${apiUrl}/svg/list_clientes`} alt="Clientes" width={250}></img>
                        <Typography variant="h4" gutterBottom align="center">
                            CORRETORES
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </Templete>
    );
}