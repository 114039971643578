import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { apiUrl } from '../../services/api';

export default function Congratulations() {
    return (
        <div style={{ backgroundColor: '#F1F1F1', width: '100%', height: '100vh', padding: '8px 152px' }}>
            <Paper elevation={4} sx={{ padding: 4 }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} container justifyContent="center">
                        <img src={`${apiUrl}/img/logo-sysprojud`} height={60} alt="Logo SysProJud"></img>
                    </Grid>
                    <Grid item xs={12}>
                        <h2 style={{ textAlign: 'center' }} >É BOM TER VOCE CONOSCO</h2>
                    </Grid>
                    <Grid item xs={12}>
                        <h4 style={{ textAlign: 'center' }} >
                            Após o pagamento, voce receberá um link no seu email cadastrado para definir sua senha para poder acessar o sistema.
                        </h4>
                    </Grid>
                    <Grid item xs={12}>
                        <h4 style={{ textAlign: 'center' }} >
                            Obrigado por escolher SysproJud.
                        </h4>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}