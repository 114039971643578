import { Button, Grid, IconButton, Paper, Tooltip } from "@mui/material";
import { FaTimes } from "react-icons/fa";
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import Label from '@mui/icons-material/Label';
import SearchIcon from '@mui/icons-material/Search';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import Templete from "../../template/Template";
import { useEffect, useState } from "react";
import { ClienteType } from "../../types/ClienteType";
import { ContainerTreeItem, CustomTreeItem } from "../../components/TreeView/TreeView";
import { useTheme } from "@mui/material";
import ListaClientes from "../../modals/clientes/ListaClientes";
import SelectSimple from "../../components/Selects/SelectSimple";
import { api } from "../../services/api";
import InputLabel from "../../components/Inputs/InputLabel";
import { arrayUf } from "../../data/dataUf";
import InputCity from "../../components/Inputs/InputCity";
import CadastroCliente from "../../modals/clientes/CadastroCliente";
import { AlertInfo } from "../../components/Alerts/Alert";
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import { formatDate, validDate } from "../../utilities/date";
import { converterMoeda, displayMoney } from "../../utilities/displayValues";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ProcessoType } from "../../types/ProcessoType";

export default function CadastroProcesso() {
    const theme = useTheme();
    const navigate = useNavigate();
    const [searchParms] = useSearchParams();
    const [infoCliente, setInfoCliente] = useState<ClienteType | null>(null);
    const [openLoad, setOpenLoad] = useState(false);
    const [openModalBuscaCliente, setOpenModalBuscaCliente] = useState(false);
    const [openModalCadastroCliente, setOpenModalCadastroCliente] = useState(false);
    const [arrServicos, setArrServicos] = useState([]);
    const [arrCausas, setArrCausas] = useState([]);
    const [arrStatusProcesso, setArrStatusProcesso] = useState([]);
    const [arrCorretores, setArrCorretores] = useState([]);
    const [arrAdvogados, setArrAdvogados] = useState([]);

    const [id_processo, setIdProcesso] = useState<number | null>(null);
    const [servico_advogado, setServicoAdv] = useState<string | number | unknown>("");
    const [causa_processual, setCausa] = useState<string | number | unknown>("");
    const [status_processo, setStatusProcesso] = useState<string | number | unknown>("");
    const [protocolo_processo, setProtocoloProcesso] = useState("");
    const [data_requerimento, setDataRequerimento] = useState("");
    const [numero_beneficio_inss, setNumeroBeneficio] = useState("");
    const [corretor_responsavel, setCorretorResponsavel] = useState<string | number | unknown>("");
    const [uf_acao_judicial, setUfAcaoJudicial] = useState<string | number | unknown>("PA");
    const [cidade_acao_judicial, setCidadeAcaoJudicial] = useState("");
    const [motivo_indeferimento_inss, setMotivoDeferimento] = useState("");
    const [salario_referencia, setSalarioReferencia] = useState("");
    const [salario_retroativo, setSalarioRetroativo] = useState("");
    const [total_causa, setTotalCausa] = useState("");
    const [valor_causa, setValorCausa] = useState("");
    const [id_advogado_processo, setIdAdvogadoCausa] = useState<string | number | unknown>("0");
    const [data_nascimento_crianca, setDataNascimentoCrianca] = useState("");
    const [local_nascimento_crianca, setLocalNascimentoCrianca] = useState("");
    const [salario_nascimento_crianca, setSalarioNascimentoCrianca] = useState("");

    async function handleSave() {
        setOpenLoad(true);
        try {
            let codigo_status_processo = 0;
            if(String(status_processo).length > 0 && arrStatusProcesso.length > 0){
                const codStatus = arrStatusProcesso.filter((i: any) => String(i.value) === String(status_processo));
                if(codStatus.length === 1) codigo_status_processo = codStatus[0]['codigo'];
            }

            const data = {
                id_processo, id_cliente_processo: infoCliente ? infoCliente.id_cliente : 0,
                servico_advogado, causa_processual, status_processo, protocolo_processo, codigo_status_processo,
                data_requerimento: validDate(data_requerimento), numero_beneficio_inss, corretor_responsavel,
                uf_acao_judicial, cidade_acao_judicial, motivo_indeferimento_inss,
                salario_referencia: converterMoeda(salario_referencia),
                salario_retroativo: converterMoeda(salario_retroativo),
                total_causa: converterMoeda(total_causa),
                valor_causa: converterMoeda(valor_causa),
                id_advogado_processo, data_nascimento_crianca: validDate(data_nascimento_crianca),
                local_nascimento_crianca, salario_nascimento_crianca: converterMoeda(salario_nascimento_crianca),
                update_processo: formatDate(new Date, 'usdH'),
            };
            const channel = id_processo ? `/updateprocesso` : `/createprocesso`;
            const response = await api.post(channel, data);
            if(response && response.status === 200 && response.data){
                if(response.data.success){
                    AlertInfo({icon:'success', title:'DADOS GRAVADOS'}).then(() => {
                        navigate(`/processos`);
                    });
                }else if(response.data.error){
                    AlertInfo({icon: 'error', title: response.data.message})
                }
            }
        } catch (error) {
            console.log(error);
            AlertInfo({ icon: 'error', title: 'Falha na conexão' });
        }
        setOpenLoad(false);
    }

    function selectCliente() {
        if (infoCliente === null) setOpenModalBuscaCliente(true);
        else if (infoCliente && infoCliente.id_cliente) setOpenModalCadastroCliente(true);
    }

    function setDataResponse(processo: ProcessoType) {
        setIdProcesso(processo.id_processo);
        setInfoCliente(processo);
        setServicoAdv(processo.servico_advogado);
        setCausa(processo.causa_processual);
        setStatusProcesso(processo.status_processo);
        setProtocoloProcesso(processo.protocolo_processo);
        if(processo.data_requerimento) setDataRequerimento(formatDate(processo.data_requerimento, "usd"));
        setNumeroBeneficio(processo.numero_beneficio_inss);
        setCorretorResponsavel(processo.corretor_responsavel);
        setUfAcaoJudicial(processo.uf_acao_judicial);
        setCidadeAcaoJudicial(processo.cidade_acao_judicial);
        setMotivoDeferimento(processo.motivo_indeferimento_inss);
        if(processo.salario_referencia > 0) setSalarioReferencia(displayMoney(processo.salario_referencia));
        if(processo.salario_retroativo > 0) setSalarioRetroativo(displayMoney(processo.salario_retroativo));
        if(processo.total_causa > 0) setTotalCausa(displayMoney(processo.total_causa));
        if(processo.valor_causa > 0) setValorCausa(displayMoney(processo.valor_causa));
        setIdAdvogadoCausa(processo.id_advogado_processo);
        if(processo.data_nascimento_crianca) setDataNascimentoCrianca(formatDate(processo.data_nascimento_crianca, "usd"));
        setLocalNascimentoCrianca(processo.local_nascimento_crianca);
        if(processo.salario_nascimento_crianca > 0) setSalarioNascimentoCrianca(displayMoney(processo.salario_nascimento_crianca));
    }

    async function loadData() {
        try {
            const response = await api.get(`/optionsprocesso`);
            if (response && response.status === 200 && response.data) {
                if (!response.data.error) {
                    if(response.data.servicos) setArrServicos(response.data.servicos);
                    if(response.data.causas) setArrCausas(response.data.causas);
                    if(response.data.status_processo) setArrStatusProcesso(response.data.status_processo);
                    if(response.data.corretores){
                        const optCorretores = response.data.corretores;
                        setArrCorretores(optCorretores.map((c:any) => ({...c, value: c.abreviacao_corretor, label: c.abreviacao_corretor})));
                    }
                    if(response.data.advogados){
                        const optAdv = response.data.advogados;
                        setArrAdvogados(optAdv.map((c:any) => ({...c, value: c.id_usuario, label: c.nome_usuario})));
                    }
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    async function loadProcesso() {
        setOpenLoad(true);
        const id = searchParms.get('id');
        if (id) {
            try {
                const data = { id_processo: id };
                const response = await api.post(`/processo`, data);
                if (response && response.status === 200 && response.data) {
                    if (response.data.error) AlertInfo({ icon: 'error', title: response.data.message });
                    else setDataResponse(response.data[0]);
                }
            } catch (error) {
                console.log(error);
                AlertInfo({ icon: "error", title: 'NÃO POSSIVEL BUSCAR DADOS' });
            }
        }
        setOpenLoad(false);
    }

    function handleRemove() {
        setInfoCliente(null);
    }

    function calcValorCausa() {
        const tC = converterMoeda(total_causa);
        const vC = converterMoeda(valor_causa);
        if(tC === 0 && vC === 0){
            const salRef = converterMoeda(salario_referencia);
            const salRet = converterMoeda(salario_retroativo);
            if(salRef > 0 || salRet > 0){
                if(String(causa_processual).includes("Aposentadoria")){
                    const calc = salRet + (salRef * 12);
                    const calcRef = salRef * 60;
                    setTotalCausa(displayMoney(calc));
                    setValorCausa(displayMoney(calc > calcRef ? calcRef : calc));
                }else if(String(causa_processual).includes("maternidade")){
                    const calc = salRet + (salRef * 4);
                    setTotalCausa(displayMoney(calc));
                    setValorCausa(displayMoney(calc));
                }
            }
        }
    }

    useEffect(() => {
        loadData();
        loadProcesso();
    }, []);

    return (
        <Templete menuActive="processos">
            <Paper elevation={4} sx={{ padding: 4 }}>
                <SimpleBackdrop open={openLoad} />
                <ListaClientes
                    open={openModalBuscaCliente}
                    setOpen={setOpenModalBuscaCliente}
                    setInfoCliente={setInfoCliente}
                />
                <CadastroCliente
                    open={openModalCadastroCliente}
                    setOpen={setOpenModalCadastroCliente}
                    cliente_id={infoCliente ? infoCliente.id_cliente : null}
                />
                <Grid container spacing={1}>
                    <Grid item xs={11} sm={5}>
                        <ContainerTreeItem
                            selectedItems="containerCliente"
                        >
                            <CustomTreeItem
                                itemId="containerCliente"
                                label={infoCliente ? infoCliente.nome_cliente : "Selecione o cliente"}
                                onClick={selectCliente}
                                color="#fff"
                                bgColor={infoCliente ? theme.palette.success.main : theme.palette.error.main}
                                labelIcon={AccountBoxIcon}
                            >
                            </CustomTreeItem>
                        </ContainerTreeItem>
                    </Grid>
                    <Grid item xs={1}>
                        {infoCliente && (
                            <Tooltip title="Remover Cliente">
                                <IconButton onClick={handleRemove}>
                                    <FaTimes color="primary" />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Grid>

                    <Grid item xs={12}></Grid>

                    <Grid item xs={12} sm={4}>
                        <SelectSimple
                            label="Serviços Advocaticios"
                            value={servico_advogado}
                            setState={setServicoAdv}
                            options={arrServicos}
                            isNone
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <SelectSimple
                            label="Causa Processual"
                            value={causa_processual}
                            setState={setCausa}
                            options={arrCausas}
                            isNone
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <SelectSimple
                            label="Situação Processo"
                            value={status_processo}
                            setState={setStatusProcesso}
                            options={arrStatusProcesso}
                            isNone
                        />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <InputLabel
                            id="protocolo_processo"
                            label="Protocolo Processo"
                            value={protocolo_processo}
                            setState={setProtocoloProcesso}
                            next="data_requerimento"
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <InputLabel
                            id="data_requerimento"
                            type="date"
                            label="Data do Requerimento"
                            value={data_requerimento}
                            setState={setDataRequerimento}
                            next="numero_beneficio_inss"
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <InputLabel
                            id="numero_beneficio_inss"
                            label="Numero Beneficio INSS"
                            value={numero_beneficio_inss}
                            setState={setNumeroBeneficio}
                            next="corretor_responsavel"
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <SelectSimple
                            id="corretor_responsavel"
                            label="Corretor Responsavel"
                            value={corretor_responsavel}
                            setState={setCorretorResponsavel}
                            options={arrCorretores}
                            next="cidade_acao_judicial"
                            isNone
                        />
                    </Grid>

                    <Grid item xs={12} sm={2}>
                        <SelectSimple
                            label="UF Ação Judicial"
                            id="uf_acao_judicial"
                            value={uf_acao_judicial}
                            setState={setUfAcaoJudicial}
                            options={arrayUf}
                            next="cidade_acao_judicial"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputCity
                            label="Cidade Ação Judicial"
                            id="cidade_acao_judicial"
                            uf={uf_acao_judicial}
                            value={cidade_acao_judicial}
                            setValue={setCidadeAcaoJudicial}
                            next="motivo_indeferimento_inss"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <InputLabel
                            id="motivo_indeferimento_inss"
                            label="Motivo Indeferimento INSS"
                            value={motivo_indeferimento_inss}
                            setState={setMotivoDeferimento}
                            next="salario_retroativo"
                        />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <InputLabel
                            id="salario_retroativo"
                            label="Saldo Retroativo"
                            value={salario_retroativo}
                            setState={setSalarioRetroativo}
                            next="salario_referencia"
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <InputLabel
                            id="salario_referencia"
                            label="Salario Referência"
                            value={salario_referencia}
                            setState={setSalarioReferencia}
                            next="total_causa"
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <InputLabel
                            id="total_causa"
                            label="Total da Causa"
                            value={total_causa}
                            setState={setTotalCausa}
                            next="valor_causa"
                            onFocus={calcValorCausa}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <InputLabel
                            id="valor_causa"
                            label="Valor da Causa"
                            value={valor_causa}
                            setState={setValorCausa}
                            next="id_advogado_processo"
                            onFocus={calcValorCausa}
                        />
                    </Grid>

                    {String(causa_processual).includes("Salário-maternidade") && (
                        <>
                            <Grid item xs={12} sm={3}>
                                <InputLabel
                                    id="data_nascimento_crianca"
                                    type="date"
                                    label="Data de Nascimento da Criança"
                                    value={data_nascimento_crianca}
                                    setState={setDataNascimentoCrianca}
                                    next="local_nascimento_crianca"
                                />
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <InputLabel
                                    id="local_nascimento_crianca"
                                    label="Local de Nascimento da Criança"
                                    value={local_nascimento_crianca}
                                    setState={setLocalNascimentoCrianca}
                                    next="salario_nascimento_crianca"
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <InputLabel
                                    id="salario_nascimento_crianca"
                                    label="Salário Minimo Nascimento da Criança"
                                    value={salario_nascimento_crianca}
                                    setState={setSalarioNascimentoCrianca}
                                />
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12} sm={3}>
                        <SelectSimple
                            id="id_advogado_processo"
                            label="Advogado(a)"
                            value={id_advogado_processo}
                            setState={setIdAdvogadoCausa}
                            options={arrAdvogados}
                            isNoneNum
                        />
                    </Grid>
                    <Grid item xs={false} sm={12}></Grid>

                    <Grid item xs={false} sm={4}></Grid>
                    <Grid item xs={12} sm={4}>
                        <Button
                            variant="contained"
                            color="success"
                            fullWidth
                            onClick={handleSave}
                        >
                            SALVAR PROCESSO
                        </Button>
                    </Grid>
                    <Grid item xs={false} sm={4}></Grid>
                </Grid>
            </Paper>
        </Templete>
    );
}