import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Grid, Paper, Typography } from "@mui/material";
import Templete from "../../template/Template";
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import InputLabel from "../../components/Inputs/InputLabel";
import { AlertInfo } from "../../components/Alerts/Alert";
import { permissions } from "../../data/permissoesUsuario";
import { UsuarioType } from "../../types/UsuarioType";
import { api } from "../../services/api";
import SelectSimple from "../../components/Selects/SelectSimple";
import { generoData } from "../../data/dataCliente";

export default function Profile() {
    const navigate = useNavigate();
    const [searchParms] = useSearchParams();
    const [openLoad, setOpenLoad] = useState(false);
    const [id_usuario, setIdUsuario] = useState<number | null>(null);
    const [nome_usuario, setNomeUsuario] = useState("");
    const [telefone_usuario, setTelefoneUsuario] = useState("");
    const [cpf_usuario, setCpfUsuario] = useState("");
    const [email_usuario, setEmailUsuario] = useState("");
    const [senha_usuario, setSenhaUsuario] = useState("");
    const [sexo_usuario, setSexoUsuario] = useState<string | number | unknown>("masculino");
    const [is_advogado, setIsAdvogado] = useState(false);
    const [cod_oab, setCodOAB] = useState("");
    const [permissoes, setPermissoes] = useState(permissions);

    async function handleSave() {
        setOpenLoad(true);
        try {
            const data = {
                id_usuario, nome_usuario, telefone_usuario,
                cpf_usuario, email_usuario, senha_usuario, sexo_usuario
            };
            const channel = id_usuario ? `/updateusuario` : `/createusuario`;
            const response = await api.post(channel, data);
            if (response && response.status === 200 && response.data) {
                if (response.data.error) {
                    console.log(response);
                    AlertInfo({ icon: 'error', title: response.data.message });
                } else if (response.data.success) {
                    AlertInfo({ icon: 'success', title: 'DADOS GRAVADOS' }).then(() => {
                        navigate('/');
                    });
                }
            }
        } catch (error) {
            console.log(error);
            AlertInfo({ icon: 'error', title: 'Falha na conexão' });
        }
        setOpenLoad(false);
    }

    function setDataResponse(dataUsuario: UsuarioType) {
        setIdUsuario(dataUsuario.id_usuario);
        setNomeUsuario(dataUsuario.nome_usuario);
        setCpfUsuario(dataUsuario.cpf_usuario);
        setTelefoneUsuario(dataUsuario.telefone_usuario);
        setEmailUsuario(dataUsuario.email_usuario);
        setSexoUsuario(dataUsuario.sexo_usuario);
    }

    async function loadData() {
        setOpenLoad(true);
        const id = searchParms.get('id');
        if (id) {
            try {
                const data = { id_usuario: id };
                const response = await api.post(`/usuario`, data);
                if (response && response.status === 200 && response.data) {
                    if (response.data.error) AlertInfo({ icon: 'error', title: response.data.message });
                    else setDataResponse(response.data[0]);
                }
            } catch (error) {
                console.log(error);
                AlertInfo({ icon: "error", title: 'NÃO POSSIVEL BUSCAR DADOS' });
            }
        }
        setOpenLoad(false);
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    return (
        <Templete menuActive="usuarios">
            <Grid container spacing={1}>
                <Grid item xs={12} sm={3}></Grid>
                <Grid item xs={12} sm={6}>
                    <Paper elevation={4} sx={{ padding: 3 }}>
                        <Grid item xs={12} container spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1" gutterBottom align="center" m={0}>
                                    Dados do usuario
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={9}>
                                <InputLabel
                                    label="Nome usuario"
                                    autoFocus={id_usuario ? false : true}
                                    next="telefone_usuario"
                                    value={nome_usuario}
                                    setState={setNomeUsuario}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <SelectSimple
                                    label="Genero"
                                    id="genero"
                                    value={sexo_usuario}
                                    setState={setSexoUsuario}
                                    options={generoData}
                                    next="endereco"
                                    isNone
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputLabel
                                    label="Telefone"
                                    id="telefone_usuario"
                                    value={telefone_usuario}
                                    setState={setTelefoneUsuario}
                                    next="cpf_usuario"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputLabel
                                    label="CPF"
                                    id="cpf_usuario"
                                    value={cpf_usuario}
                                    setState={setCpfUsuario}
                                    next="email_usuario"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputLabel
                                    label="Email"
                                    id="email_usuario"
                                    value={email_usuario}
                                    setState={setEmailUsuario}
                                    next="senha_usuario"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputLabel
                                    label="Senha"
                                    type="password"
                                    id="senha_usuario"
                                    value={senha_usuario}
                                    setState={setSenhaUsuario}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={3}></Grid>

                <Grid item xs={false} sm={5}></Grid>
                <Grid item xs={12} sm={2}>
                    <Button
                        variant="contained"
                        fullWidth
                        color="success"
                        onClick={handleSave}
                    >
                        Salvar
                    </Button>
                </Grid>
                <Grid item xs={false} sm={5}></Grid>

                <SimpleBackdrop open={openLoad} />
            </Grid>
        </Templete>
    );
}