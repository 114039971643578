import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Templete from "../../template/Template";
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import SelectSimple from "../../components/Selects/SelectSimple";
import InputLabel from "../../components/Inputs/InputLabel";
import InputCity from "../../components/Inputs/InputCity";
import DropZoneFile from "../../components/DropZone/DropZoneFile";
import { AlertConfirm, AlertInfo } from "../../components/Alerts/Alert";
import { arrayUf } from "../../data/dataUf";
import { formatDate } from "../../utilities/date";
import { api, apiUrl } from "../../services/api";
import { EscritorioType } from "../../types/EscritorioType";

export default function Escritorio() {
    const [openLoad, setOpenLoad] = useState(false);
    const [id_escritorio, setIdEscritorio] = useState<number | null>(null);
    const [nome_escritorio, setNomeEscritorio] = useState("");
    const [razao_social_escritorio, setRazaoSocialEscritorio] = useState("");
    const [cnpj_escritorio, setCnpjEscritorio] = useState("");
    const [endereco_escritorio, setEnderecoEscritorio] = useState("");
    const [numero_escritorio, setNumeroEscritorio] = useState("");
    const [bairro_escritorio, setBairroEscritorio] = useState("");
    const [complemento_escritorio, setComplementoEscritorio] = useState("");
    const [uf_escritorio, setUfEscritorio] = useState<string | number | unknown>("PA");
    const [cidade_escritorio, setCidadeEscritorio] = useState("");
    const [cep_escritorio, setCepEscritorio] = useState("");
    const [telefone_escritorio, setTelefoneEscritorio] = useState("");
    const [email_escritorio, setEmailEscritorio] = useState("");
    const [logo_escritorio, setLogoEscritorio] = useState<null | string>(null);

    async function handleSave() {
        setOpenLoad(true);
        try {
            const data = {
                id_escritorio, nome_escritorio, razao_social_escritorio, cnpj_escritorio,
                endereco_escritorio, numero_escritorio, bairro_escritorio, complemento_escritorio,
                uf_escritorio, cidade_escritorio, cep_escritorio, telefone_escritorio, email_escritorio,
                update_escritorio: formatDate(new Date(), 'usdH')
            };
            const response = await api.post(`/updateescritorio`, data);
            if (response && response.status === 200 && response.data) {
                if (response.data.error) {
                    AlertInfo({ icon: 'error', title: response.data.message });
                } else {
                    AlertInfo({ icon: 'success', title: "Dados Gravados" });
                }
            }
        } catch (error) {
            console.log(error);
            AlertInfo({ icon: 'error', title: "Falha na Conexão" });
        }
        setOpenLoad(false);
    }

    function fillDataEscritorio(dataEscritorio: EscritorioType) {
        setIdEscritorio(dataEscritorio.id_escritorio);
        setNomeEscritorio(dataEscritorio.nome_escritorio);
        setRazaoSocialEscritorio(dataEscritorio.razao_social_escritorio);
        setCnpjEscritorio(dataEscritorio.cnpj_escritorio);
        setEnderecoEscritorio(dataEscritorio.endereco_escritorio);
        setNumeroEscritorio(dataEscritorio.numero_escritorio);
        setBairroEscritorio(dataEscritorio.bairro_escritorio);
        setComplementoEscritorio(dataEscritorio.complemento_escritorio);
        setUfEscritorio(dataEscritorio.uf_escritorio);
        setCidadeEscritorio(dataEscritorio.cidade_escritorio);
        setCepEscritorio(dataEscritorio.cep_escritorio);
        setTelefoneEscritorio(dataEscritorio.telefone_escritorio);
        setEmailEscritorio(dataEscritorio.email_escritorio);
        if(dataEscritorio.logo_escritorio){
            setLogoEscritorio(`${apiUrl}/imguploaded/${dataEscritorio.logo_escritorio}`);
        }
    }

    async function loadData() {
        setOpenLoad(true);
        try {
            const response = await api.get(`/escritorio`);
            if (response && response.status === 200 && response.data) {
                if (response.data.error) {
                    AlertInfo({ icon: 'error', title: response.data.message });
                } else {
                    fillDataEscritorio(response.data[0]);
                }
            }
        } catch (error) {
            console.log(error);
            AlertInfo({ icon: 'error', title: "Falha na Conexão" });
        }
        setOpenLoad(false);
    }
    
    function deleteLogoEscritorio() {
        AlertConfirm({title: "Remover Logo do sistema?"}).then(async res => {
            if(res.isConfirmed){
                try {
                    const filename = String(logo_escritorio).replace(`${apiUrl}/imguploaded/`, "");
                    const response = await api.delete(`/removelogoescritorio/${filename}`);
                    if(response && response.status === 200 && response.data){
                        if(response.data.success){
                            AlertInfo({ icon: 'success', title: "Arquivo Removido" });
                            setLogoEscritorio(null);
                        }else if(response.data.error) {
                            AlertInfo({ icon: 'error', title: response.data.message });                            
                        }
                    }
                } catch (error) {
                    console.log(error);
                    AlertInfo({ icon: 'error', title: "Falha na Conexão" });
                }
            }
        });
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <Templete menuActive="escritorio">
            <Paper elevation={4} sx={{ padding: 4 }}>
                <SimpleBackdrop open={openLoad} />

                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <h2 style={{ textAlign: 'center' }} >Dados Escritorio</h2>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputLabel
                            id="nome_escritorio"
                            label="Nome Escritorio"
                            value={nome_escritorio}
                            setState={setNomeEscritorio}
                            next="razao_social_escritorio"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputLabel
                            id="razao_social_escritorio"
                            label="Razão Social Escritorio"
                            value={razao_social_escritorio}
                            setState={setRazaoSocialEscritorio}
                            next="cnpj_escritorio"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputLabel
                            id="cnpj_escritorio"
                            label="CNPJ Escritorio"
                            value={cnpj_escritorio}
                            setState={setCnpjEscritorio}
                            next="endereco_escritorio"
                            mask="cnpj"
                        />
                    </Grid>
                    <Grid item xs={12} sm={10}>
                        <InputLabel
                            id="endereco_escritorio"
                            label="Endereço Escritorio"
                            value={endereco_escritorio}
                            setState={setEnderecoEscritorio}
                            next="numero_escritorio"
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <InputLabel
                            id="numero_escritorio"
                            label="Numero Escritorio"
                            value={numero_escritorio}
                            setState={setNumeroEscritorio}
                            next="bairro_escritorio"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputLabel
                            id="bairro_escritorio"
                            label="Bairro Escritorio"
                            value={bairro_escritorio}
                            setState={setBairroEscritorio}
                            next="cidade_escritorio"
                        />
                    </Grid>
                    <Grid item xs={12} sm={1}>
                        <SelectSimple
                            label="UF"
                            id="uf"
                            value={uf_escritorio}
                            setState={setUfEscritorio}
                            options={arrayUf}
                            next="cidade_escritorio"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputCity
                            id="cidade_escritorio"
                            uf={uf_escritorio}
                            value={cidade_escritorio}
                            setValue={setCidadeEscritorio}
                            next="cep_escritorio"
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <InputLabel
                            id="cep_escritorio"
                            label="CEP Escritorio"
                            value={cep_escritorio}
                            setState={setCepEscritorio}
                            next="complemento_escritorio"
                            mask="cep"
                        />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <InputLabel
                            id="complemento_escritorio"
                            label="Complemento"
                            value={complemento_escritorio}
                            setState={setComplementoEscritorio}
                            next="telefone_escritorio"
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <InputLabel
                            id="telefone_escritorio"
                            label="Telefone Escritorio"
                            value={telefone_escritorio}
                            setState={setTelefoneEscritorio}
                            next="email_escritorio"
                            mask="telefone"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputLabel
                            id="email_escritorio"
                            label="Email Escritorio"
                            value={email_escritorio}
                            setState={setEmailEscritorio}
                        />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <DropZoneFile
                            route="/saveLogoEscritorio"
                            accept={['.png', '.jpg']}
                            currentFile={logo_escritorio}
                            handleDelete={deleteLogoEscritorio}
                        />
                    </Grid>
                    <Grid item xs={false} sm={12}></Grid>

                    <Grid item xs={false} sm={4}></Grid>
                    <Grid item xs={12} sm={4}>
                        <Button
                            variant="contained"
                            fullWidth
                            color="success"
                            onClick={handleSave}
                        >
                            SALVAR
                        </Button>
                    </Grid>
                    <Grid item xs={false} sm={4}></Grid>
                </Grid>
            </Paper>
        </Templete>
    );
}