import { useNavigate } from 'react-router-dom';
import { FaHome, FaUsers, FaUserLock, FaBuilding } from "react-icons/fa";
import Divider from '@mui/material/Divider';
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import AddIcon from '@mui/icons-material/Add';
// import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import { ThemeProvider } from '@mui/material/styles';
import { colorTheme } from '../styles/MaterialTheme';
import { isPermite } from '../utilities/isPermite';

let theme = {
    ...colorTheme,
    components: {
        MuiTab: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: colorTheme.palette.primary.main,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
                contained: {
                    boxShadow: 'none',
                    '&:active': {
                        boxShadow: 'none',
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    padding: colorTheme.spacing(1),
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    borderRadius: 4,
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgb(255,255,255,0.3)',
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        color: colorTheme.palette.secondary.main,
                    },
                    '&.Mui-selected svg': {
                        color: colorTheme.palette.secondary.main,
                    }
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: 14,
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: 'white',
                    minWidth: 'auto',
                    marginRight: colorTheme.spacing(2),
                    '& svg': {
                        fontSize: 20,
                    },
                },
            },
        },
    },
};

const item = {
    py: '2px',
    px: 3,
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover, &:focus': {
        bgcolor: 'rgba(255, 255, 255, 0.08)',
    },
};

const itemCategory = {
    boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
    py: 1.5,
    px: 3,
};

type NavigatorProps = DrawerProps & {
    menuActive: string
}

export default function Navigator(props: NavigatorProps) {
    const { menuActive = '', ...other } = props;
    const navigate = useNavigate();

    function navigateMenu(menu: string) {
        navigate(menu);
    }

    return (
        <ThemeProvider theme={theme}>
            <Drawer variant="permanent" {...other}>
                <List disablePadding>
                    <ListItem sx={{ ...item, ...itemCategory, fontSize: 22, color: '#fff' }} >
                        SysproJud
                    </ListItem>

                    <Box sx={{ bgcolor: '#101F33', paddingTop: 2 }}>
                        <ListItem disablePadding >
                            <ListItemButton selected={menuActive === 'home'} sx={item} onClick={() => navigateMenu('/')}>
                                <ListItemIcon><FaHome /></ListItemIcon>
                                <ListItemText>Inicio</ListItemText>
                            </ListItemButton>
                        </ListItem>
                        {isPermite("1") && (
                            <ListItem disablePadding >
                                <ListItemButton selected={menuActive === 'usuarios'} sx={item} onClick={() => navigateMenu('/usuarios')}>
                                    <ListItemIcon><FaUserLock /></ListItemIcon>
                                    <ListItemText>Usuarios</ListItemText>
                                </ListItemButton>
                            </ListItem>
                        )}
                        {isPermite("20") && (
                            <ListItem disablePadding >
                                <ListItemButton selected={menuActive === 'escritorio'} sx={item} onClick={() => navigateMenu('/escritorio')}>
                                    <ListItemIcon><FaBuilding /></ListItemIcon>
                                    <ListItemText>Escritorio</ListItemText>
                                </ListItemButton>
                            </ListItem>
                        )}
                        {isPermite("10") && (
                            <ListItem disablePadding >
                                <ListItemButton selected={menuActive === 'clientes'} sx={item} onClick={() => navigateMenu('/clientes')}>
                                    <ListItemIcon><FaUsers /></ListItemIcon>
                                    <ListItemText>Clientes</ListItemText>
                                </ListItemButton>
                            </ListItem>
                        )}
                        {isPermite("30") && (
                            <ListItem disablePadding >
                                <ListItemButton selected={menuActive === 'processos'} sx={item} onClick={() => navigateMenu('/processos')}>
                                    <ListItemIcon><PlagiarismIcon /></ListItemIcon>
                                    <ListItemText>Processos</ListItemText>
                                </ListItemButton>
                            </ListItem>
                        )}
                        <ListItem disablePadding >
                            <ListItemButton selected={menuActive === 'cadastros'} sx={item} onClick={() => navigateMenu('/cadastros')}>
                                <ListItemIcon><AddIcon /></ListItemIcon>
                                <ListItemText>Cadastros</ListItemText>
                            </ListItemButton>
                        </ListItem>
                        <Divider sx={{ mt: 2 }} />
                    </Box>
                </List>
            </Drawer>
        </ThemeProvider>
    );
}