import { Button, Chip, Fab, Grid, Paper, Typography, useTheme } from "@mui/material";
import Templete from "../../template/Template";
import { useEffect, useState } from "react";
import TableDataSimple from "../../components/Tables/TableDataSimple";
import { api, apiUrl } from "../../services/api";
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import { Link, useNavigate } from "react-router-dom";
import CardSimple from "../../components/Cards/CardSimple";
import { endMes, formatDate, startMes } from "../../utilities/date";
import { AlertInfo } from "../../components/Alerts/Alert";
import { ProcessoType } from "../../types/ProcessoType";
import IconMenu from "../../components/Menu/IconMenu";
import EditIcon from '@mui/icons-material/Edit';
import DescriptionIcon from '@mui/icons-material/Description';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SearchIcon from '@mui/icons-material/Search';
import HistoricoProcesso from "../../modals/processos/HistoricoProcesso";
import SearchProcessos from "./SearchProcessos";

export default function Processos() {
    const theme = useTheme();
    const navigate = useNavigate();
    const [activeProcess, setActiveProcess] = useState<null | ProcessoType>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openLoad, setOpenLoad] = useState(false);
    const [openSearch, setOpenSearch] = useState(false);
    const [openHistroy, setOpenHistory] = useState(false);
    const [arrHistory, setArrHistory] = useState([]);
    const [allRows, setAllRows] = useState([]);
    const [rows, setRows] = useState([]);
    const [labelFilter, setLabelFilter] = useState("");
    const [emptyMessage, setEmptyMessage] = useState({ icon: 'text_files', message: "Processos" });
    const [counts, setCounts] = useState({
        cancelados: 0, preparacao: 0, andamento: 0, concluidos: 0
    });
    const columns = [
        {
            id: 'protocolo_processo',
            numeric: false,
            label: 'PROTOCOLO',
        },
        {
            id: 'nome_cliente',
            numeric: false,
            label: 'NOME',
        },
        {
            id: 'causa_processual',
            numeric: false,
            label: 'CAUSA',
        },
        {
            id: 'status_processo',
            numeric: false,
            label: 'STATUS',
        },
        {
            id: 'data_requerimento',
            numeric: false,
            label: 'DATA REQ.',
            format: (dt: any) => formatDate(dt),
        },
        // {
        //     id: 'telefone_cliente',
        //     numeric: false,
        //     label: 'TELEFONE',
        // },
        // {
        //     id: 'cidade_cliente',
        //     numeric: false,
        //     label: 'CIDADE',
        // },
    ];

    function editProcess() {
        if (activeProcess) navigate(`/cadastroprocesso?id=${activeProcess.id_processo}`);
    }

    function openDocument() {
        if (activeProcess) {
            setOpenLoad(true);
            setAnchorEl(null);
            api.get(`/createpeticao/${activeProcess.id_processo}`, { responseType: 'blob' }).then(response => {
                if (response.status === 204) {
                    return AlertInfo({ icon: 'error', title: "Documento não localizado" });
                }
                const url = window.URL.createObjectURL(response.data);
                const link = document.createElement('a');
                link.href = url;
                link.download = `${activeProcess.causa_processual} - ${activeProcess.nome_cliente}.docx`;
                link.click();
                window.URL.revokeObjectURL(url);
            });
            setOpenLoad(false);
        }
    }

    async function openHistoryStatus() {
        if (activeProcess) {
            setOpenLoad(true);
            setAnchorEl(null);
            try {
                const response = await api.get(`/eventosproceso/${activeProcess.id_processo}`);
                if (response.status === 200 && response.data) {
                    if (response.data.error) {
                        AlertInfo({ icon: 'error', title: response.data.message });
                    } else {
                        setArrHistory(response.data);
                        setOpenHistory(true);
                    }
                }
            } catch (error) {
                console.log(error);
                AlertInfo({ icon: 'error', title: 'Falha na conexão' });
            }
            setOpenLoad(false);
        }
    }

    const arrMenu = [
        { icon: <EditIcon fontSize="small" color="primary" />, title: "Editar Processo", handle: editProcess },
        { icon: <DescriptionIcon fontSize="small" color="primary" />, title: "Gerar Petição", handle: openDocument },
        { icon: <AccessTimeIcon fontSize="small" color="primary" />, title: "Historico de Status", handle: openHistoryStatus }
    ];


    function handleClick(process: ProcessoType, event: any) {
        setActiveProcess(process);
        setAnchorEl(event.currentTarget);
    }

    async function loadData() {
        setOpenLoad(true);
        try {
            const response = await api.post(`/processos`);
            if (response && response.status === 200 && response.data) {
                if (response.data.error) AlertInfo({ icon: 'error', title: response.data.message });
                else setAllRows(response.data);
                setLabelFilter(`${formatDate(startMes(), "br")} à ${formatDate(endMes(), 'br')}`);
            }
        } catch (error) {
            console.log(error);
            AlertInfo({ icon: 'error', title: 'Falha na Conexão' });
        }
        setOpenLoad(false);
    }

    function filterProcess(cod: number) {
        const filter = allRows.filter((i: ProcessoType) => i.codigo_status_processo === cod);
        setRows(filter);
    }

    useEffect(() => {
        const cancelados = allRows.filter((i: ProcessoType) => i.codigo_status_processo === 1).length;
        const preparacao = allRows.filter((i: ProcessoType) => i.codigo_status_processo === 2).length;
        const andamento = allRows.filter((i: ProcessoType) => i.codigo_status_processo === 3).length;
        const concluidos = allRows.filter((i: ProcessoType) => i.codigo_status_processo === 4).length;
        setCounts({ cancelados, preparacao, andamento, concluidos });
        setRows(allRows);
    }, [allRows]);

    useEffect(() => {
        loadData();
    }, []);

    return (
        <Templete menuActive="processos">
            <Grid container spacing={1}>
                <Grid item xs={12} sm={2}>
                    {/* {isPermission(2) && ( */}
                    <Link to="/cadastroprocesso">
                        <Button
                            variant="contained"
                            fullWidth
                        >
                            NOVO PROCESSO
                        </Button>
                    </Link>
                    {/* )} */}
                </Grid>
                <Grid item xs={false} sm={6}></Grid>
                <Grid item xs={12} sm={4} justifyContent="flex-end" container alignItems="center">
                    <Chip label={labelFilter} variant="outlined" onDelete={loadData} />
                    <Fab size="medium" color="primary" aria-label="search" onClick={() => setOpenSearch(true)} >
                        <SearchIcon />
                    </Fab>
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12} sm={3}>
                    <CardSimple
                        title="CANCELADOS"
                        txt={counts.cancelados}
                        color="bgError"
                        handle={() => filterProcess(1)}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <CardSimple
                        title="EM PREPARAÇÃO"
                        txt={counts.preparacao}
                        color="bgPrimary"
                        handle={() => filterProcess(2)}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <CardSimple
                        title="EM ANDAMENTO"
                        txt={counts.andamento}
                        color="bgBlue"
                        handle={() => filterProcess(3)}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <CardSimple
                        title="CONCLUIDOS"
                        txt={counts.concluidos}
                        color="bgSuccess"
                        handle={() => filterProcess(4)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TableDataSimple
                        rows={rows}
                        columns={columns}
                        handleClick={handleClick}
                    />
                    <IconMenu
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        arrMenu={arrMenu}
                    />
                </Grid>

                <SimpleBackdrop open={openLoad} />
                <HistoricoProcesso
                    open={openHistroy}
                    setOpen={setOpenHistory}
                    historico={arrHistory}
                />
                <SearchProcessos
                    open={openSearch}
                    setOpen={setOpenSearch}
                    setAllRows={setAllRows}
                    setLabelFilter={setLabelFilter}
                />
            </Grid>
        </Templete>
    );
}