export const removeMask = (mask: string) => {
    const regra = /[^a-z0-9]/gi;
    const value = mask.replace(regra, "");
    return value;
}

export const converterMoeda = (value: string | number) => {
    if(value === ''){
		return 0;
	}else{
        var val = String(value);
		var real = val.replace(".","");
        var real2 = real.replace(".","");
		var cents = real2.replace(",",".");
        return Number(cents);
	}
}

export const displayMoney = (value: string | number) => {
    if(value || Number(value) === 0){
        const format = Intl.NumberFormat('pt-br', {
            minimumFractionDigits: 2, 
            maximumFractionDigits: 2
        }).format(Number(value));
        return format;
    }else{
        return '';
    }
}