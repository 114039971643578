import { Dispatch, SetStateAction } from "react";
import { Grid, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Modal from "../../components/Modal/Modal";
import { formatDate } from "../../utilities/date";
import { HistoricoProcessoType } from "../../types/HistoricoProcessoType";

type HistoricoProcessoProps = {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    historico: HistoricoProcessoType[]
}

export default function HistoricoProcesso({ open, setOpen, historico }: HistoricoProcessoProps) {

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title="Historico de Status do Processo"
            maxWidth="xs"
            zIndex={2222}
        >
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <List dense>
                        {historico.map((his, index) => (
                            <ListItem key={`event${index}`}>
                                <ListItemIcon>
                                    <AccessTimeIcon color="primary" />
                                </ListItemIcon>
                                <ListItemText
                                    primary={his.status_processo}
                                    secondary={formatDate(his.data_evento, 'br')}
                                />
                            </ListItem>
                        ))}

                        {historico.length === 0 && (
                            <ListItem>
                                <ListItemText
                                    primary="Nenhuma mudança de status adicionada"
                                />
                            </ListItem>
                        )}
                    </List>
                </Grid>
            </Grid>
        </Modal>
    );
}