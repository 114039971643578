import { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Templete from "../../template/Template";
import TableDataSimple from "../../components/Tables/TableDataSimple";
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import InputSearch from "../../components/Inputs/InputSearch";
import { AlertInfo } from "../../components/Alerts/Alert";
import CadastroCliente from "../../modals/clientes/CadastroCliente";
import { formatDate } from "../../utilities/date";
import { removeMask } from "../../utilities/displayValues";
import { api, apiUrl } from "../../services/api";
import { ClienteType } from "../../types/ClienteType";


export default function Clientes() {
    const navigate = useNavigate();
    const [openLoad, setOpenLoad] = useState(false);
    const [openCadastroCliente, setOpenCadastroCliente] = useState(false);
    const [novoCliente, setNovoCliente] = useState(0);
    const [cliente_id, setClienteId] = useState(0);
    const [search,setSearch] = useState("");
    const [rows, setRows] = useState([]);
    const [emptyMessage, setEmptyMessage] = useState({ icon: 'list_clientes', message: "Clientes" });
    const columns = [
        {
            id: 'id_cliente',
            numeric: false,
            label: 'COD.',
        },
        {
            id: 'nome_cliente',
            numeric: false,
            label: 'NOME',
        },
        {
            id: 'data_nascimento_cliente',
            numeric: false,
            label: 'NASCIMENTO',
            format: (dt: any) => formatDate(dt),
        },
        {
            id: 'cpf_cliente',
            numeric: false,
            label: 'CPF',
        },
        {
            id: 'rg_cliente',
            numeric: false,
            label: 'RG',
        },
        {
            id: 'telefone_cliente',
            numeric: false,
            label: 'TELEFONE',
        },
        {
            id: 'cidade_cliente',
            numeric: false,
            label: 'CIDADE',
        },
    ];

    async function handleListAll() {
        setOpenLoad(true);
        try {
            const response = await api.get(`/clientes`);
            if (response && response.status === 200 && response.data) {
                if (response.data.error) {
                    AlertInfo({ icon: 'error', title: response.data.message })
                } else {
                    if (response.data.length > 0) setRows(response.data);
                    else setEmptyMessage({ icon: 'empty', message: "Nenhum cliente cadastrado" });
                }
            }
        } catch (error) {
            console.log(error);
            AlertInfo({ icon: 'error', title: "FALHA NA CONEXÃO" });
        }
        setOpenLoad(false);
    }

    function getClient(cli: ClienteType) {
        if(cli.nome_cliente.toLowerCase().includes(search.toLowerCase())) return cli;
        if(removeMask(cli.cpf_cliente).includes(removeMask(search))) return cli;
        if(removeMask(cli.rg_cliente).includes(removeMask(search))) return cli;
    }

    async function searchCliente() {
        if(search.length > 0){
            setOpenLoad(true);
            try {
                const response = await api.get(`/clientes`);
                if (response && response.status === 200 && response.data) {
                    if (response.data.error) {
                        AlertInfo({ icon: 'error', title: response.data.message })
                    } else {
                        if (response.data.length > 0){
                            const all = response.data;
                            const isCli = all.filter(getClient);
                            setRows(isCli);
                            if(isCli.length === 0) setEmptyMessage({ icon: 'empty', message: "Nenhum cliente localizado" });
                        }else{
                            setEmptyMessage({ icon: 'empty', message: "Nenhum cliente cadastrado" });
                        }
                    }
                }
            } catch (error) {
                console.log(error);
                AlertInfo({ icon: 'error', title: "FALHA NA CONEXÃO" });
            }
            setOpenLoad(false);
        }
    }

    async function loadCliente() {
        if (novoCliente > 0) {
            setOpenLoad(true);
            try {
                const response = await api.post(`/cliente`, { id_cliente: novoCliente });
                if (response && response.status === 200 && response.data) {
                    if (response.data.error) {
                        AlertInfo({ icon: 'error', title: response.data.message })
                    } else {
                        if (response.data.length > 0) setRows(response.data);
                    }
                }
            } catch (error) {
                console.log(error);
                AlertInfo({ icon: 'error', title: "FALHA NA CONEXÃO" });
            }
            setOpenLoad(false);
        }
    }

    function handleClick(dataCliente: ClienteType) {
        if (dataCliente && dataCliente.id_cliente) {
            setClienteId(dataCliente.id_cliente);
            setOpenCadastroCliente(true);
        }
    }

    function reset() {
        setRows([]);
        setEmptyMessage({ icon: 'list_clientes', message: "Clientes" });
    }

    useEffect(() => {
        loadCliente();
    }, [novoCliente]);

    return (
        <Templete menuActive="clientes">
            <Grid container spacing={1}>
                <CadastroCliente
                    open={openCadastroCliente}
                    setOpen={setOpenCadastroCliente}
                    setNovoCliente={setNovoCliente}
                    cliente_id={cliente_id}
                    setClienteId={setClienteId}
                    reset={reset}
                />
                <Grid item xs={12} sm={2}>
                    {/* {isPermission(2) && ( */}
                    {/* <Link to="/clientes"> */}
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={() => setOpenCadastroCliente(true)}
                        >
                            NOVO CLIENTE
                        </Button>
                    {/* </Link> */}
                    {/* )} */}
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={handleListAll}
                    >
                        LISTAR TODOS
                    </Button>
                </Grid>
                <Grid item xs={false} sm={4}></Grid>
                <Grid item xs={12} sm={4}>
                    <InputSearch
                        placeholder="Buscar por Nome, CPF, RG"
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        handleClick={searchCliente}
                        onKeyUp={e => {
                            if(e.key === 'Enter') searchCliente();
                        }}
                    />
                </Grid>

                {rows.length > 0 && (
                    <Grid item xs={12}>
                        <TableDataSimple
                            rows={rows}
                            columns={columns}
                            handleClick={handleClick}
                        />
                    </Grid>
                )}
                {rows.length === 0 && (
                    <Grid item xs={12} container spacing={1} justifyContent="center" direction="column" alignItems="center">
                        <br />
                        <img src={`${apiUrl}/svg/${emptyMessage.icon}`} alt="Clientes" width={250}></img>
                        <Typography variant="h4" gutterBottom align="center">
                            {emptyMessage.message}
                        </Typography>
                    </Grid>
                )}
                <SimpleBackdrop open={openLoad} />
            </Grid>
        </Templete>
    );
}