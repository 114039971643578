import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

type buttonActionsProps = {
    color: 'primary' | 'secondary' | 'success' | 'error',
    text: string,
    handle: () => {}
};

type ModalProps = {
    open: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    title: string,
    children: JSX.Element | JSX.Element[]
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg',
    zIndex?: number
    buttonActions?: buttonActionsProps[]
    actionClose?: () => void
};

export default function Modal({ open, setOpen, title, children, maxWidth = 'xs', zIndex = 999, buttonActions = [], actionClose }: ModalProps) {

    const handleClose = () => {
        setOpen(false);
        if(actionClose) actionClose();
    };

    return (
        <Dialog
            open={open}
            style={{ zIndex }}
            maxWidth={maxWidth}
            scroll="paper"
            fullWidth
        >
            <DialogTitle>{title}</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                {children}
            </DialogContent>
            {buttonActions.length > 0 && (
                <DialogActions>
                    {buttonActions.map((b, i) => (
                        <Button
                            key={`ba${i}`}
                            variant='contained'
                            onClick={b.handle}
                            color={b.color}
                        >
                            {b.text}
                        </Button>
                    ))}
                </DialogActions>
            )}
        </Dialog>
    );
}