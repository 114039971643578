export const estadoCivilData = [
    {value: 'casado', label: 'casado'},
    {value: 'casada', label: 'casada'},
    {value: 'divorciado', label: 'divorciado'},
    {value: 'divorciada', label: 'divorciada'},
    {value: 'em união estavel', label: 'em união estavel'},
    {value: 'solteiro', label: 'solteiro'},
    {value: 'solteira', label: 'solteira'},
    {value: 'viúvo', label: 'viúvo'},
    {value: 'viúva', label: 'viúva'},
];

export const generoData = [
    { value: 'masculino', label: 'masculino' },
    { value: 'feminino', label: 'feminino' },
]

export const nacionalidadeData = [
    { value: 'brasileiro', label: 'brasileiro' },
    { value: 'brasileira', label: 'brasileira' },
    { value: 'estrangeiro', label: 'estrangeiro' },
    { value: 'estrangeira', label: 'estrangeira' },
]