import { Link } from "react-router-dom";
import { FaAngleRight, FaUserGear, FaEarthAmericas, FaRightToBracket } from "react-icons/fa6";
import { Typography } from "@mui/material";
import { apiUrl } from "../../services/api";
import "./login.css";

export default function InfoCard() {
    return (
        <div className="infoCardContainer" style={{ backgroundImage: `url(${apiUrl}/img/bg-login)` }}>
            <Typography variant="h5" gutterBottom align="center">
                Bem vindo!
            </Typography>
            <Link to="/cadastro">
                <div className="rowActionInfoCard">
                    <div className="startIconRow">
                        <FaRightToBracket />
                    </div>
                    <div className="centerTextRow">
                        <span>Não tem acesso? Cadastre-se</span>
                    </div>
                    <div className="endIconRow">
                        <FaAngleRight />
                    </div>
                </div>
            </Link>
            <br />
            <div className="rowActionInfoCard">
                <div className="startIconRow">
                    <FaUserGear />
                </div>
                <div className="centerTextRow">
                    <span>Entre em contato o suporte</span>
                </div>
                <div className="endIconRow">
                    <FaAngleRight />
                </div>
            </div>
            <br />
            <div className="rowActionInfoCard">
                <div className="startIconRow">
                    <FaEarthAmericas />
                </div>
                <div className="centerTextRow">
                    <span>Conheça mais sobre o SysproJud</span>
                </div>
                <div className="endIconRow">
                    <FaAngleRight />
                </div>
            </div>
        </div>
    );
}