import * as React from 'react';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import ContentCut from '@mui/icons-material/ContentCut';
import ContentCopy from '@mui/icons-material/ContentCopy';
import ContentPaste from '@mui/icons-material/ContentPaste';
import Cloud from '@mui/icons-material/Cloud';
import { Menu } from '@mui/material';

type arrMenuProps = {
    title: string
    icon: JSX.Element
    handle: () => void
}

type IconMenuProps = {
    anchorEl: any,
    setAnchorEl: any,
    arrMenu: arrMenuProps[]
}

export default function IconMenu({ anchorEl, setAnchorEl, arrMenu }: IconMenuProps) {
    function handleClose() {
        setAnchorEl(null);
    }

    return (
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            <MenuList>
                {arrMenu.map((item, index) => (
                    <MenuItem key={`menuItem${index}`} onClick={item.handle}>
                        <ListItemIcon>
                            {item.icon}
                        </ListItemIcon>
                        <ListItemText>{item.title}</ListItemText>
                    </MenuItem>
                ))}
            </MenuList>
        </Menu>
    );
}