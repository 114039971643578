import { Dispatch, MouseEventHandler, SetStateAction } from 'react';
import { FaTimes } from 'react-icons/fa';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

type DrawerAppProps = {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    title: string
    children: JSX.Element
};

export default function DrawerApp({open, setOpen, title, children}:DrawerAppProps) {

    const toggleDrawer = () => {
        setOpen(false);
    };

    const list = () => (
        <div
            role="presentation"
            style={{width: 'auto', maxWidth: 350, minWidth: 310, backgroundColor: '#fff'}}
        >
            <List>
                <ListItem>
                    <ListItemText primary={title} />
                    <ListItemIcon>
                        <FaTimes onClick={toggleDrawer} style={{cursor: 'pointer'}} />
                    </ListItemIcon>
                </ListItem>
                { children }
            </List>
        </div>
    );

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={toggleDrawer}
        >
            {list()}
        </Drawer>
    );
}